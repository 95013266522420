import { AppState } from '../../rootReducer';

export const getTemplates = (store: AppState) => store.emailMarketing.automation.templates || [];
export const isTemplatesListLoading = (store: AppState) => store.emailMarketing.automation.loadingTemplates || false;
export const getCoreStatus = (store: AppState) => store.emailMarketing.automation.isCoreActive || false;
export const isCoreStatusLoading = (store: AppState) => store.emailMarketing.automation.loadingCoreStatus || false;
export const isTemplateSaving = (store: AppState) => store.emailMarketing.automation.savingTemplate || false;
export const getWelcomeModalState = (store: AppState) => store.emailMarketing.automation.showWelcomeModal || false;
export const getAssignSuccessModalState = (store: AppState) =>
  store.emailMarketing.automation.assignSuccessModal || { status: false };
export const getCampaigns = (store: AppState) => store.emailMarketing.automation.campaigns;
export const isCampaignsListLoading = (store: AppState) =>
  store.emailMarketing.automation.loadingCampaignsStatus || false;
export const isCampaignsListFetched = (store: AppState) => store.emailMarketing.automation.fetchedCampaignsStatus;

export const isCampaignCreating = (store: AppState) => store.emailMarketing.automation.creatingCampaign || false;
