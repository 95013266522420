import moment from 'moment';
import { cloneDeep, get } from 'lodash';
import { call, put, select } from '@redux-saga/core/effects';
import i18n from 'i18n';
import { formatString } from 'services/utils';
import { ApiGateway } from 'services/apiGateway';
import { dateFormatter } from 'services/dateFormatter';
import { getPagePath } from 'services/navigation';
import { localizationService } from 'services/localization';
import { getIsContactsWithoutLocations, getSelectedLocations } from 'store/locations';
import {
  convertMailBoxesToHTML,
  emojiStrip,
  prepareHTMLToSend,
  stripHtmlTags
} from 'shared-components/EmailTemplateEditor/utils';
import {
  API_ANDROID_PAY_URL,
  AUTOMATION_CAMPAIGN_STATUSES,
  CAMPAIGN_SCHEDULE_DELAY
} from 'appConstants/emailMarketing';
import { getMerchantNumbersMap } from 'store/settings';
import { getMenuPages, getPathname } from 'store/navigation';
import { convertTemplate } from '../campaignEditor';
import { EmailMarketingSettings, getEmCustomerSettings, SocialLink } from '../settings';
import { Coupon, EmailTemplateEditorState } from '../emailTemplateEditor';
import allowedEmoji from './allowedEmoji.json';
import {
  CONTACT_MEMBER_TYPES_LENGTH,
  ContactMemberType
} from '../../../components/EmailMarketing/createCampaign/types';
import { ContactsList as ContactsListType } from '../contacts';

const UNKNOWN_ERROR_MSG = 'Unknown error during reading file';

export const stripZeroWidthSpace = (value: string | undefined) => {
  if (typeof value !== 'string') return value;
  return value.replace(/\uFEFF/g, '');
};

// IE adds character with code 8206 to Intl.DateTimeFormat.format execution result.
export const sanitizeDateString = (date: string) =>
  date
    .split('')
    .map((c: string) => c.charCodeAt(0))
    .filter((n: number) => n !== 8206)
    .map((n: number) => String.fromCharCode(n))
    .join('');

export const getDefaultTemplateName = (): string =>
  formatString(
    i18n.t('template_DefaultName', 'Template {0}'),
    sanitizeDateString(dateFormatter.dateTimeTimeZone(moment().tz(moment.tz.guess())))
  ).join('');

export const getDefaultCampaignName = () => {
  const date = dateFormatter.dateTimeTimeZone(moment().tz(moment.tz.guess()));
  return `${i18n.t('campaign_DefaultName', 'Untitled')} ${sanitizeDateString(date)}`;
};

/* Check if file is an image type */
export const checkFile = (file: any) =>
  new Promise((resolve, reject) => {
    // @ts-ignore
    const fileReader = new window.FileReader();

    fileReader.onloadend = (e: any) => {
      const arr = new Uint8Array(e.target.result).subarray(0, 4);
      let header = '';
      let type;

      for (let i = 0; i < arr.length; i += 1) {
        header += arr[i].toString(16);
      }
      switch (header) {
        case '89504e47':
          type = 'image/png';
          break;
        case '47494638':
          type = 'image/gif';
          break;
        case 'ffd8ffe0':
        case 'ffd8ffe1':
        case 'ffd8ffe2':
          type = 'image/jpeg';
          break;
        default:
          type = 'unknown';
          break;
      }
      resolve(type);
    };

    fileReader.onerror = () => {
      reject(fileReader?.error?.message || UNKNOWN_ERROR_MSG);
    };

    fileReader.readAsArrayBuffer(file);
  });

/* Load and resize image file */
const loadFile = (file: any, maxImageWidth: number) =>
  new Promise((resolve, reject) => {
    // @ts-ignore
    const reader = new window.FileReader();

    reader.onload = (upload: any) => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      if (!ctx) throw Error('Error: canvas.getContext returned undefined');

      // @ts-ignore
      const image = new window.Image();

      image.onload = () => {
        // Set max dimensions for image
        canvas.width = image.width > maxImageWidth ? maxImageWidth : image.width;
        canvas.height = (canvas.width / image.width) * image.height;

        // Draw image
        ctx.fillStyle = '#fff'; // / set white fill style
        ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.width, canvas.height);

        // Draw image as png
        const imageDataURL = canvas.toDataURL('image/png');

        resolve(imageDataURL);
      };
      image.onerror = () => {
        reject(new Error('Image error'));
      };

      image.src = upload.target.result;
    };
    reader.onerror = () => {
      reject(reader?.error?.message || UNKNOWN_ERROR_MSG);
    };

    reader.readAsDataURL(file);
  });

export const imageFileProceed = async (file: any, maxImageWidth: number) => {
  /* Validation file */
  const validation = await checkFile(file);

  if (validation === 'unknown') {
    return Object.assign({ error: 'Unsupported file type. Supported formats - JPEG, PNG, GIF.' });
  }

  /* Load and resize image if needed */
  const imageDataURL = await loadFile(file, maxImageWidth);

  /* Return object with prepared state */
  return Object.assign({
    image: imageDataURL,
    filename: file.name
  });
};

/* Convert DataUrl (Base64) to File Type */
export const dataURLtoFile = (dataURI: string) =>
  new Promise(resolve => {
    const parts = dataURI.split(',');
    const firstPart = get(parts, '[0]', '');
    const secondPart = get(parts, '[1]', '');

    const mime = firstPart.match(/:(.*?);/)[1];
    const byteString = window.atob(secondPart);
    // @ts-ignore
    const ab = new window.ArrayBuffer(byteString.length);
    // @ts-ignore
    const ia = new window.Uint8Array(ab);

    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }
    resolve(new window.Blob([ab], { type: mime }));
  });

/* Get Current Time + 5 Min  - this time used for sending campaign */
export const getCampaignTime = (date?: string) =>
  date ? moment(date) : moment(new Date()).add(CAMPAIGN_SCHEDULE_DELAY, 'm');

export const getContactsCount = (totalCount: number, selectedCount: number, selectAllMode: boolean) => {
  if (selectAllMode) return totalCount - selectedCount;
  return selectedCount;
};

export const emojiFilter = (emoji: any) => {
  const { unified } = emoji;
  return Object.keys(allowedEmoji).includes(unified.toLowerCase());
};

export const isInternetExplorer = () => {
  const ua = window.navigator.userAgent;

  const msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  const trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    const rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  const edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }

  // other browser
  return false;
};

export const isSocialLinksListEmpty = (list: SocialLink[]) => list.every(item => !item.link);

export function* saveToAndroidPay(boxData: Coupon, merchantName: string) {
  const { title = merchantName, text = '', startDate, endDate, locations } = boxData;

  const body = {
    merchantName,
    title,
    description: stripHtmlTags(text),
    start: startDate,
    end: endDate,
    locations
  };

  const response = yield ApiGateway.saveToAndroidPay(body);

  return { ...boxData, androidPayLink: `${API_ANDROID_PAY_URL}${response.JWT}` };
}

export function* getTemplateDataForSave(
  editorState: EmailTemplateEditorState,
  settings: EmailMarketingSettings,
  name: string,
  updateBox: (id: string, boxData: any) => any,
  isDanubeWithLoyalty: boolean,
  isDanubeAccount: boolean,
  accountName: string,
  katanaKey?: string,
  publicSitesDomain?: string,
  chockStoneDomain?: string
) {
  const { styles } = editorState;

  const translations = {
    templateSubjectDefault: i18n.t('template_SubjectDefault', 'Check this out!'),
    templatePreheaderDefault: i18n.t('template_PreheaderDefault', "You don't want to miss this!"),
    mailTableUnsubscribeText: i18n.t('mailTable_UnsubscribeText', 'CLICK HERE TO UNSUBSCRIBE'),
    mailTableOptOutFromTheLoyaltyProgram: i18n.t(
      'mailTable_OptOutFromTheLoyaltyProgram',
      'OPT OUT FROM THE LOYALTY PROGRAM'
    ),
    mailTableUnsubscribeFromEmailMarketing: i18n.t(
      'mailTable_UnsubscribeFromEmailMarketing',
      'UNSUBSCRIBE FROM EMAIL MARKETING'
    )
  };

  const subject = editorState.subject || translations.templateSubjectDefault;
  const preheader = editorState.subject ? editorState.preheader : translations.templatePreheaderDefault;

  if (!settings) return null;

  const email = get(settings, 'customer.email', '');
  const organizationName = get(settings, 'customer.displayName', '');
  const displayName = editorState.displayName || get(settings, 'customer.displayName', '');

  const copiedComponents = cloneDeep(editorState.components);

  for (let i = 0; i < copiedComponents.length; i += 1) {
    const component = copiedComponents[i];
    const { boxData } = component;
    if (component.type === 'coupon' && boxData.androidPay) {
      component.boxData = yield saveToAndroidPay(boxData, organizationName);
      yield put(updateBox(component.id, component.boxData));
    }
  }

  const extendedSettings = { ...settings, customerLocale: localizationService.getLocale() };

  const body = convertMailBoxesToHTML(
    copiedComponents,
    styles,
    extendedSettings,
    translations,
    isDanubeWithLoyalty,
    isDanubeAccount,
    accountName,
    katanaKey,
    publicSitesDomain,
    chockStoneDomain
  );

  const html = prepareHTMLToSend(body, emojiStrip(preheader), emojiStrip(subject), styles?.backgroundColor);
  const components = convertTemplate(copiedComponents);

  return {
    components,
    fromEmail: email,
    fromName: displayName,
    html: encodeURIComponent(html),
    name: encodeURIComponent(name),
    preheader,
    styles,
    subject: encodeURIComponent(subject)
  };
}

export function* fetchContacts(payload: any, tabState: any, fetchList: any, actions: any) {
  try {
    const applicationState = yield select();

    const settings = getEmCustomerSettings(applicationState);
    const selectedLocations = getSelectedLocations(applicationState);
    const includeContactsWithoutLocations = getIsContactsWithoutLocations(applicationState);

    const merchantNumbersMap = getMerchantNumbersMap(applicationState);

    const pathname = getPathname(applicationState);
    const menuPages = getMenuPages(applicationState);
    const contactsPathname = getPagePath(menuPages, 'emailMarketing_root', 'emailMarketing_contacts');
    const createCampaignPathname = getPagePath(menuPages, 'emailMarketing_root', 'emailMarketing_createCampaign');

    const preserveKeyword =
      pathname === contactsPathname || pathname === createCampaignPathname ? tabState.keyword : '';

    const { page, limit, clearPrevious } = payload;
    const keyword = typeof payload.keyword === 'string' ? payload.keyword : preserveKeyword;

    const { setList, success } = actions;
    const pageNumber = parseInt(page, 10);
    const limitSize = limit ? parseInt(limit, 10) : tabState.limit;

    if (settings && settings.customerId === -1) {
      console.log('fetchContacts customerId is not defined or equal -1');
      return;
    }

    if (clearPrevious) yield put(setList([]));

    const locations = selectedLocations.map(merchantSequenceKey => merchantNumbersMap[merchantSequenceKey]);

    const contactsResponse = yield call(fetchList, {
      ...payload,
      limit: limitSize,
      keyword,
      sort: tabState.sort,
      locations,
      includeContactsWithoutLocations
    });
    const contactsCountResponse = yield call(ApiGateway.fetchContactsCount, {
      locations,
      includeContactsWithoutLocations
    });

    const emContactsCount = yield call(ApiGateway.fetchContactsCount, {
      locations,
      includeContactsWithoutLocations,
      memberTypes: [ContactMemberType.EMAIL_MARKETING]
    });

    const loyaltyContactsCount = yield call(ApiGateway.fetchContactsCount, {
      locations,
      includeContactsWithoutLocations,
      memberTypes: [ContactMemberType.LOYALTY]
    });

    const { data } = contactsResponse;

    yield put(
      success({
        page: pageNumber,
        limit: limitSize,
        data,
        totalCount: contactsResponse?.meta.total,
        keyword,
        overallCount: contactsCountResponse.totalCount || 0,
        emContactsCount: emContactsCount.totalCount || 0,
        loyaltyContactsCount: loyaltyContactsCount.totalCount || 0,
        sort: tabState.sort
      })
    );
  } catch (e) {
    console.error(e);
    yield put(actions.failure());
  }
}

export const getTemplateStatus = (template: any) => {
  if (template.campaignStatus === 0) return AUTOMATION_CAMPAIGN_STATUSES.inactive;
  if (template.campaignStatus === 1) return AUTOMATION_CAMPAIGN_STATUSES.active;

  const { promo } = template;
  if (!promo) return AUTOMATION_CAMPAIGN_STATUSES.inactive;

  const { status, campaignId } = promo;

  if (!status) return AUTOMATION_CAMPAIGN_STATUSES.inactive;
  if (campaignId) return AUTOMATION_CAMPAIGN_STATUSES.confirmed;
  return AUTOMATION_CAMPAIGN_STATUSES.unconfirmed;
};

export const getTemplateLoyaltyStatus = (template: any) => {
  const { status, campaignId } = template;

  if (!status) return AUTOMATION_CAMPAIGN_STATUSES.inactive;
  if (campaignId) return AUTOMATION_CAMPAIGN_STATUSES.confirmed;
  return AUTOMATION_CAMPAIGN_STATUSES.unconfirmed;
};

export const alignRows = (source: string, className: string) => {
  const parser = new DOMParser();
  const document = parser.parseFromString(source, 'text/html');

  document.body.childNodes.forEach((node: ChildNode) => {
    const element = node as HTMLElement;
    if (element.nodeType !== 1) return;
    if (element.className.indexOf('ql-align-') === -1) {
      element.classList.add(className);
    }
  });

  return document.body.innerHTML;
};

export const getSelectedContactsCount = (contacts: ContactsListType, selectedMemberTypes: ContactMemberType[]) => {
  switch (selectedMemberTypes.length) {
    case 1:
      return selectedMemberTypes.includes(ContactMemberType.EMAIL_MARKETING)
        ? contacts.emContactsCount
        : contacts.loyaltyContactsCount;
    case CONTACT_MEMBER_TYPES_LENGTH:
      return contacts.totalCount;
    default:
      return 0;
  }
};
